<template>
  <div>
    <b-card class="about">
      <b-row>
        <b-col class="photo" md="4">
          <b-card-img width="20px" :src="require('../assets/logo.png')" alt="Images"></b-card-img>
        </b-col>
        <b-col md="8">
          <b-card-body :title="'Mgr. '+ $t('caroline') +' '+ $t('claus')">
            <b-card-text>
              This is a wider card with supporting text as a natural lead-in to additional content.
              This content is a little bit longer.
            </b-card-text>
            <b-container fluid>
              <b-row>
                <b-col sm="2">
                  <label>{{ $t('education') }}:</label>
                </b-col>
                <b-row>
                  <b-col sm="10">
                    Portugalský jazyk a literatura (navazující magisterské studium)
                  </b-col>
                  <b-col>
                    <label>2017-2019</label>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="10">
                    Portugalský jazyk a literatura (bakalářské studium)
                  </b-col>
                  <b-col sm="3">
                    2014-2017
                  </b-col>
                </b-row>
              </b-row>
            </b-container>
          </b-card-body>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "AboutMe"
}
</script>

<style scoped>

.about {
  width: 100%
}
</style>
