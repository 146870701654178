<template>
<!--    <div class="footer">-->
<!--        <b-card bg-variant="dark" text-variant="white">-->
<!--          footer-->
<!--        </b-card>-->
<!--    </div>-->
  <div class="bg-secondary">
    <div class="container py-3">
      <div class="row pt-1 align-items-center">
        <div
            class="col-xl-12 col-bg-12 col-md-12 col-sm-12 pbelow"
            style="color: white;"
        >
          <span> © 2021-{{new Date().getFullYear()}} Copyright: {{$t('caroline')}} {{$t('claus')}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>
