<template>
  <div
      id="contact"
      class="py-4 p-st"
      :class="{
      'bg-light': !nightMode,
      'bg-dark2': nightMode,
      'text-light': nightMode,
    }"
  >
    <div class="contact-container">
      <div
          class="text-center"
          data-aos="fade"
          data-aos-once="true"
          data-aos-duration="1000"
      >
        <div
            class="title text-center contact-title"
            :class="{ pgray: !nightMode, 'text-light': nightMode }"
        >{{$t('contact')}}</div
        >
        <span
            class="title text-center contact-title"
            :class="{ pgray: !nightMode, 'text-light': nightMode }"
        >{{$t('reachMe')}}</span
        >
      </div>
      <hr
          width="100%"
          :class="{ pgray: !nightMode, 'bg-secondary': nightMode }"
      />
      <br />
    <b-form @submit.prevent="sendEmail(form)" @reset="onReset" v-if="show">
        <b-form-group
            id="input-group-2"
            :label="$t('name')"
            label-for="input-2"
            class="mb-3 contact-form"
        >
          <b-form-input
              cols="255"
              id="input-2"
              v-model="form.name"
              :placeholder="$t('name')"
              required
              type="text"
              name="user_name"
              class="contact-input input-container"
              style="transition-delay: 0.2s"
          />
        </b-form-group>

        <b-form-group
            id="input-group-1"
            :label="$t('email')"
            label-for="input-1"
            class="my-3"
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-duration="1000"
        >
          <b-form-input
              id="input-1"
              v-model="form.email"
              type="email"
              :placeholder="$t('email')"
              required
              name="user_email"
              class="contact-input input-container"
              :class="{
              pgray: !nightMode,
              'pgray-dark': nightMode,
              'text-light': nightMode,
            }"
              style="transition-delay: 0.4s"
          />
        </b-form-group>

        <b-form-group id="input-group-3" :label="$t('text')" label-for="input-3"
            class="my-3"
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-duration="1000"
        >
          <b-form-textarea
              id="textarea"
              name="comment"
              v-model="form.comment"
              :placeholder="$t('text')"
              max-rows="6"
              class="contact-input input-container input-container-textarea"
              rows="4"
              :class="{
              pgray: !nightMode,
              'pgray-dark': nightMode,
              'text-light': nightMode,
            }"
              style="transition-delay: 0.6s"
          ></b-form-textarea>
        </b-form-group>

        <b-button
            type="submit"
            variant="primary"
            class="mt-1 btn mb-3 contact-submit"
            data-aos="fade"
            data-aos-once="true"
            data-aos-duration="1000"
            data-aos-offset="50"
        >
          Send
        </b-button>
      </b-form>

      <Snackbar
          :showSnackbar="showSnackbar"
          @close="closeSnackbar"
          :snackbarMessage="snackbarMessage"
          :snackbarColor="snackbarColor"
      />

      <ul class="soc-media-icons">
        <li>
          <a href="mailto:carolineklauszova@gmail.com" target="_blank" rel="noopener">
            <b-img :src="require('../assets/gmail_icon_2020.svg')" alt="gmail"></b-img>
          </a>
        </li>
        <li>
          <a href="https://api.whatsapp.com/send?phone=+420731964961" target="_blank" rel="noopener">
            <b-img :src="require('../assets/WhatsApp.svg')" alt="whatsapp"></b-img>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import config from "../../config";
import emailjs from "emailjs-com";

// import Snackbar from "./helpers/Snackbar";
import Snackbar from "@/components/helpers/Snackbar";

export default {
  name: "Contact",
  components: {
    Snackbar,
  },
  props: {
    nightMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        email: '',
        name: '',
        comment: null,
        checked: [],
        file: null,
      },
      show: true
    }
  },
  computed: {
    datas() {
      return this.form
    }
  },

  methods: {
    onSubmit(event) {
      event.preventDefault()
      alert(JSON.stringify(this.form))
    },

    sendEmail: function (form) {
      emailjs.send('service_m4x0h76', 'template_v659q77', {name: form.name, file: form.file, email: form.email, text: form.comment}, 'user_2InVMq3YL5bFBXNZQ5cEo')
          .then((result) => {
            console.log('SUCCESS!', result.status, result.text);
          }, (error) => {
            console.log('FAILED...', error);
          });

    },

    onReset(event) {
      event.preventDefault()
      // Reset our form values
      this.form.email = ''
      this.form.name = ''
      this.form.food = null
      this.form.checked = []
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 30px;
  font-weight: 500;
}

.pgray-dark {
  background-color: #3c4148 !important;
}

@media screen and (max-width: 1000px) {

  .btn {
    width: 90%;
  }
}
</style>
