<template>
  <div
      class="py-4 p-st"
      :class="{
      'bg-light': !nightMode,
      'bg-dark2': nightMode,
      'text-light': nightMode,
    }"
  >
    <h3>
    {{$t('transl1')}}
  </h3>
  <br />
  <b-row style="margin: 1%">
        <b-col>
          <b-card :title="$t('crims')" tag="article" style="min-width: 15rem;" class="mb-2">
                    <b-card-action>
            <b-button href='#contact'>
              {{$t('order')}}
            </b-button>
          </b-card-action>
          </b-card>

      </b-col>
      <b-col>
        <b-card :title="$t('birth')" tag="article" style="min-width: 15rem;" class="mb-2">
          <b-card-action>
            <b-button href='#contact'>
              {{$t('order')}}
            </b-button>
          </b-card-action>
        </b-card>
      </b-col>
      <b-col>
        <b-card :title="$t('wedding')" tag="article" style="min-width: 15rem;" class="mb-2">
          <b-card-action>
            <b-button href='#contact'>
              {{$t('order')}}
            </b-button>
          </b-card-action>
        </b-card>
      </b-col>
    </b-row>
    <b-row style="margin: 1%">
      <b-col>
        <b-card :title="$t('death')" tag="article" style="min-width: 15rem;" class="mb-2">
                  <b-card-action>
            <b-button href='#contact'>
              {{$t('order')}}
            </b-button>
          </b-card-action>
        </b-card>
      </b-col>
      <b-col>
        <b-card :title="$t('declaration')" tag="article" style="min-width: 15rem;" class="mb-2">
          <b-card-action>
            <b-button href='#contact'>
              {{$t('order')}}
            </b-button>
          </b-card-action>
        </b-card>
      </b-col>
      <b-col>
        <b-card :title="$t('attorney')" tag="article" style="min-width: 15rem;" class="mb-2">
          <b-card-action>
            <b-button href='#contact'>
              {{$t('order')}}
            </b-button>
          </b-card-action>

        </b-card>
      </b-col>
      <b-col>
        <b-card :title="$t('others')" tag="article" style="min-width: 15rem;" class="mb-2">
            <b-card-action>
            <b-button href='#contact'>
              {{$t('order')}}
            </b-button>
          </b-card-action>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
export default {
  name: "Translations"
}
</script>

<style scoped>

</style>
