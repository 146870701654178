<template>
<!--   <div-->
<!--       class="fixed-top p-st"-->
<!--   >-->
     <div>
    <b-navbar id="navbar" toggleable="md" type="dark" variant="dark">
      <b-navbar-brand class="d-md-none">
        www.traducaroline.com
       </b-navbar-brand>
      <b-navbar-toggle target="collapse-area"></b-navbar-toggle>
      <b-collapse id="collapse-area" is-nav>
        <b-navbar-nav class="d-none d-md-block">
          <b-nav-text>
            <div class="logo-container">
<!--              <img class="navbar-desktop-logo" src="@/assets/image0.png" alt="Tryvium Logo">-->
            </div>
          </b-nav-text>
        </b-navbar-nav>
        <b-navbar-nav>
          <b-nav-item href="#about">{{ $t('aboutme') }}</b-nav-item>
          <b-nav-item href="#pricing">{{$t('price')}}</b-nav-item>
          <b-nav-item href="#translations">{{$t('translations')}}</b-nav-item>
          <b-nav-item href="#contact">{{$t('contacts')}}</b-nav-item>
<!--          <b-nav-item href="#feedback">{{$t('feedback')}}</b-nav-item>-->
        </b-navbar-nav>
        <b-nav-item-dropdown right :text="$i18n.locale" class="d-none d-md-block">
          <b-dropdown-item v-for="i in $i18n.availableLocales" @click="$i18n.locale = i">
            <b-img :src="require('../assets/flags/'+i+'.svg')" :alt=i style="height: 24px; width: 24px"/> {{i}}
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-button-group class="d-md-none">
          <b-button selected @click="$i18n.locale = i" v-for="i in $i18n.availableLocales">
            <b-img :src="require('../assets/flags/cs.svg')"></b-img>
<!--            TODO -->
          </b-button>
        </b-button-group>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  
}
</script>

<style lang="scss" scoped>
//.navbar-desktop-logo {
//  z-index: 2000;
//  zoom: 0.1;
//  overflow:hidden; //position:relative;
//  position: absolute;
//  top: -10em;
//  left: -20em;
//}
//.logo-container {
//  position: relative;
//  width: 200px;
//}
.navbar-mobile-logo {
  position: absolute;
  zoom: 0.05;
  left: 0;
}
.name {
  display: block;
}
.nav {
  font-size: 20px;
  background: yellow;
}

.navbar {
  border-bottom: 1px solid black;
}
.nav-item {
  //padding-left: 2em;
}

#nav {
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
