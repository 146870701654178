<template>
  <div>
    <b-card class="text-center">
      <b-card-text>
      {{$t('contact1')}}
    </b-card-text>
    <b-form @submit.prevent="sendEmail(form)" @reset="onReset" v-if="show">
      <b-form-group
        id="input-group-1"
        :label="$t('email')"
        label-for="input-1"
      >
        <b-form-input
          id="input-1"
          v-model="form.email"
          type="email"
          placeholder="Enter email"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" :label="$t('name')" label-for="input-2">
        <b-form-input
        cols="255"
          id="input-2"
          v-model="form.name"
          placeholder="Enter name"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group hidden id="input-group-2" :label="$t('file')" label-for="input-2">
        <b-form-file
      v-model="form.file"
      :state="Boolean(form.file)"
      :placeholder="$t('choosefile')"
      drop-placeholder="Drop file here..."
    ></b-form-file>
      </b-form-group>

      <b-form-group id="input-group-3" :label="$t('text')" label-for="input-3">
        <b-form-textarea id="textarea" name="comment"
                    v-model="form.comment" placeholder="Write your comment..." rows="5" max-rows="6">
                  </b-form-textarea>
      </b-form-group>

      <b-button type="submit" variant="primary">Submit</b-button>
    </b-form>
  </b-card>
    
  </div>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  name: "ContactMe",
  data() {
      return {
        form: {
          email: '',
          name: '',
          comment: null,
          checked: [],
          file: null,
        },
        show: true
      }
  },
  computed: {
    datas() {
      return this.form
    }
  },
  
  methods: {
    onSubmit(event) {
      event.preventDefault()
      alert(JSON.stringify(this.form))
    },

    sendEmail: function (form) {
        emailjs.send('service_m4x0h76', 'template_v659q77', {name: form.name, file: form.file, email: form.email, text: form.comment}, 'user_2InVMq3YL5bFBXNZQ5cEo')
        .then((result) => {
            console.log('SUCCESS!', result.status, result.text);
        }, (error) => {
            console.log('FAILED...', error);
        });  
      
    },

    onReset(event) {
      event.preventDefault()
      // Reset our form values
      this.form.email = ''
      this.form.name = ''
      this.form.food = null
      this.form.checked = []
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  }
}
</script>

<style scoped>

</style>
