<template>
<!--  <div>-->
<!--    &lt;!&ndash; Spinner until main image is loaded &ndash;&gt;-->
<!--    <div id="loading-screen" style="display: none">-->
<!--      <i class="fas fa-spinner fa-pulse"></i>-->
<!--      <span>Loading</span>-->
<!--    </div>-->

<!--&lt;!&ndash;    &lt;!&ndash; Navigation starts &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;    <header id="header">&ndash;&gt;-->
<!--&lt;!&ndash;      <div class="header-container">&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="logo-container">&ndash;&gt;-->
<!--&lt;!&ndash;          <img src="./assets/logo.svg" alt="Bohdan Kulčyckyj" id="logo">&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;        <nav id="navigation">&ndash;&gt;-->
<!--&lt;!&ndash;          <ul>&ndash;&gt;-->
<!--&lt;!&ndash;            <li onclick="handleMobileMenu()"><span id="landing-link" class="nav-link" onclick="navigateSmooth('#landing')">Home</span></li>&ndash;&gt;-->
<!--&lt;!&ndash;            <li onclick="handleMobileMenu()"><span id="about-link" class="nav-link" onclick="navigateSmooth('#about')">About</span></li>&ndash;&gt;-->
<!--&lt;!&ndash;            <li onclick="handleMobileMenu()"><span id="projects-link" class="nav-link" onclick="navigateSmooth('#projects')">Projects</span></li>&ndash;&gt;-->
<!--&lt;!&ndash;            <li onclick="handleMobileMenu()"><span id="contact-link" class="nav-link" onclick="navigateSmooth('#contact')">Contact</span></li>&ndash;&gt;-->
<!--&lt;!&ndash;          </ul>&ndash;&gt;-->
<!--&lt;!&ndash;        </nav>&ndash;&gt;-->
<!--&lt;!&ndash;        <div id="menu-btn-container">&ndash;&gt;-->
<!--&lt;!&ndash;          <div id="menu-btn"></div>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--&lt;!&ndash;    </header>&ndash;&gt;-->
<!--&lt;!&ndash;    &lt;!&ndash; Navigation ends &ndash;&gt;&ndash;&gt;-->

<!--    &lt;!&ndash; landing Page starts &ndash;&gt;-->
<!--    <section id="landing">-->
<!--      <div id="landing-content">-->
<!--        <div id="landing-text">-->
<!--          <h1 id="landing-h1">-->
<!--            <span>I am</span>-->
<!--&lt;!&ndash;            <span id="my-name">Mgr. {{$t('caroline')}} {{$t('claus')}}</span>&ndash;&gt;-->
<!--            <span>Mgr. {{$t('caroline')}} {{$t('claus')}}</span>-->
<!--            <span>Junior Web Developer</span>-->
<!--          </h1>-->
<!--          <div class="buttons-container">-->
<!--&lt;!&ndash;            <a href="./assets/cv.pdf" target="_blank" rel="noopener" id="cv">CV</a>&ndash;&gt;-->
<!--            <span onclick="navigateSmooth('#contact')" id="hire-me">Hire me</span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--&lt;!&ndash;      <img src="assets/parallax/paralax-man.png" alt="the guy" id="landing-img" />&ndash;&gt;-->
<!--    </section>-->
<!--    &lt;!&ndash; landing Page ends &ndash;&gt;-->

<!--    &lt;!&ndash; About starts &ndash;&gt;-->
<!--    <section id="about">-->
<!--      <div class="about-container">-->
<!--        <div class="about-text">-->
<!--          <h1 class="reveal">A few words about me</h1>-->
<!--          <div class="reveal">-->
<!--            <p>I am 21 years old self-taught programmer based in Czech Republic and focused on Front-end Web Development.</p>-->
<!--            <p>After finishing business Academy I studied for a while at the University of Economics in Prague.-->
<!--              Because of bad circumstances I was forced to leave university. Luckily I found something, which kept me motivated and convinced me don't give up.-->
<!--            </p>-->
<!--            <p>-->
<!--              I realised that programming is the best for me because there are basically no limits and many ways to achieve the same goal.-->
<!--              I am eager for learning new technologies and creating amazing things.-->
<!--            </p>-->
<!--            <p>-->
<!--              I am looking for any kind of cooperation. If you have some proposals, please <span onclick="navigateSmooth('#contact')" style="color: grey; cursor: pointer;">contact me</span>.-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="about-skills" id="about-skills"></div>-->
<!--    </section>-->
<!--    &lt;!&ndash; About ends &ndash;&gt;-->

<!--    &lt;!&ndash; Projects starts &ndash;&gt;-->
<!--    <section id="projects">-->
<!--      <div class="projects-container">-->
<!--        <h1 class="reveal">My Projects</h1>-->
<!--        <div class="cards">-->
<!--          <div class="flip-card reveal">-->
<!--            <div class="flip-card-inner">-->
<!--              <div class="flip-card-front">-->
<!--                <div class="project-name">-->
<!--                  <h2>E-commerce</h2>-->
<!--                </div>-->
<!--                <div class="project-photo">-->
<!--&lt;!&ndash;                  <img src="./assets/projects/watches.png" alt="project-img">&ndash;&gt;-->
<!--                </div>-->
<!--                <div class="used-tech">-->
<!--&lt;!&ndash;                  <img src="./assets/techs/react.svg" alt="react" />&ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="flip-card-back">-->
<!--                <div class="file">-->
<!--                  &lt;!&ndash; <img src="./assets/flip-file.svg" alt="project"> &ndash;&gt;-->
<!--                </div>-->
<!--                <div class="project-description">-->
<!--                  <p>My first try to build an E-commerce website. I used here Material UI and Redux.-->
<!--                    CommerceJS helps me with product management and Stripe with payments. It is still in development.-->
<!--                  </p>-->
<!--                </div>-->
<!--                <div class="links">-->
<!--                  <a href="https://github.com/bohdanKulcyckyj/watches-ecommerce" target="_blank" rel="noopener">-->
<!--&lt;!&ndash;                    <img src="./assets/github.svg" alt="github" />&ndash;&gt;-->
<!--                  </a>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="flip-card reveal">-->
<!--            <div class="flip-card-inner">-->
<!--              <div class="flip-card-front">-->
<!--                <div class="project-name">-->
<!--                  <h2>Games site</h2>-->
<!--                </div>-->
<!--                <div class="project-photo">-->
<!--&lt;!&ndash;                  <img src="./assets/projects/games-site.png" alt="project-img">&ndash;&gt;-->
<!--                </div>-->
<!--                <div class="used-tech">-->
<!--&lt;!&ndash;                  <img src="./assets/techs/react.svg" alt="react" />&ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="flip-card-back">-->
<!--                <div class="file">-->
<!--                  &lt;!&ndash; <img src="./assets/flip-file.svg" alt="project"> &ndash;&gt;-->
<!--                </div>-->
<!--                <div class="project-description">-->
<!--                  <p>This website shows you some information about games. With React I used here Material UI and Redux.-->
<!--                    Games data provides RAWG API.-->
<!--                  </p>-->
<!--                </div>-->
<!--                <div class="links">-->
<!--                  <a href="https://github.com/bohdanKulcyckyj/my_games_site" target="_blank" rel="noopener">-->
<!--&lt;!&ndash;                    <img src="./assets/github.svg" alt="github" />&ndash;&gt;-->
<!--                  </a>-->
<!--                  <a href="https://games-site.herokuapp.com/" target="_blank" rel="noopener">-->
<!--&lt;!&ndash;                    <img src="assets/file.svg" alt="file" />&ndash;&gt;-->
<!--                  </a>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="flip-card reveal">-->
<!--            <div class="flip-card-inner">-->
<!--              <div class="flip-card-front">-->
<!--                <div class="project-name">-->
<!--                  <h2>Music App</h2>-->
<!--                </div>-->
<!--                <div class="project-photo">-->
<!--&lt;!&ndash;                  <img src="./assets/projects/music-app.png" alt="project-img">&ndash;&gt;-->
<!--                </div>-->
<!--                <div class="used-tech">-->
<!--&lt;!&ndash;                  <img src="./assets/techs/react.svg" alt="react" />&ndash;&gt;-->
<!--&lt;!&ndash;                  <img src="./assets/techs/sass.svg" alt="sass" />&ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="flip-card-back">-->
<!--                <div class="file">-->
<!--                  &lt;!&ndash; <img src="./assets/flip-file.svg" alt="project"> &ndash;&gt;-->
<!--                </div>-->
<!--                <div class="project-description">-->
<!--                  <p>My first React project which I did during the React and Redux course.</p>-->
<!--                </div>-->
<!--                <div class="links">-->
<!--                  <a href="https://github.com/bohdanKulcyckyj/Music-App" target="_blank" rel="noopener">-->
<!--&lt;!&ndash;                    <img src="./assets/github.svg" alt="github" />&ndash;&gt;-->
<!--                  </a>-->
<!--                  <a href="https://productive-playlist-app.herokuapp.com/" target="_blank" rel="noopener">-->
<!--&lt;!&ndash;                    <img src="assets/file.svg" alt="file" />&ndash;&gt;-->
<!--                  </a>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="flip-card reveal">-->
<!--            <div class="flip-card-inner">-->
<!--              <div class="flip-card-front">-->
<!--                <div class="project-name">-->
<!--                  <h2>Time Manager</h2>-->
<!--                </div>-->
<!--                <div class="project-photo">-->
<!--&lt;!&ndash;                  <img src="./assets/projects/time-manager.png" alt="project-img">&ndash;&gt;-->
<!--                </div>-->
<!--                <div class="used-tech">-->
<!--&lt;!&ndash;                  <img src="./assets/techs/html.svg" alt="html" />&ndash;&gt;-->
<!--&lt;!&ndash;                  <img src="./assets/techs/css.svg" alt="css" />&ndash;&gt;-->
<!--&lt;!&ndash;                  <img src="./assets/techs/javascript.png" alt="js" />&ndash;&gt;-->
<!--&lt;!&ndash;                  <img src="./assets/techs/sass.svg" alt="css" />&ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="flip-card-back">-->
<!--                <div class="file">-->
<!--                  &lt;!&ndash; <img src="./assets/flip-file.svg" alt="project"> &ndash;&gt;-->
<!--                </div>-->
<!--                <div class="project-description">-->
<!--                  <p>-->
<!--                    In order to improve my skills I started to build a Time Management Tool, which can also help me to reduce time wasting.-->
<!--                  </p>-->
<!--                </div>-->
<!--                <div class="links">-->
<!--                  <a href="https://github.com/bohdanKulcyckyj/Time_Manager" target="_blank" rel="noopener">-->
<!--&lt;!&ndash;                    <img src="./assets/github.svg" alt="github" />&ndash;&gt;-->
<!--                  </a>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="flip-card reveal">-->
<!--            <div class="flip-card-inner">-->
<!--              <div class="flip-card-front">-->
<!--                <div class="project-name">-->
<!--                  <h2>Formula Helper</h2>-->
<!--                </div>-->
<!--                <div class="project-photo">-->
<!--&lt;!&ndash;                  <img src="./assets/projects/formula-helper.png" alt="project-img">&ndash;&gt;-->
<!--                </div>-->
<!--                <div class="used-tech">-->
<!--&lt;!&ndash;                  <img src="./assets/techs/html.svg" alt="html" />&ndash;&gt;-->
<!--&lt;!&ndash;                  <img src="./assets/techs/css.svg" alt="css" />&ndash;&gt;-->
<!--&lt;!&ndash;                  <img src="./assets/techs/javascript.png" alt="js" />&ndash;&gt;-->
<!--&lt;!&ndash;                  <img src="./assets/techs/sass.svg" alt="css" />&ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="flip-card-back">-->
<!--                <div class="file">-->
<!--                  &lt;!&ndash; <img src="./assets/flip-file.svg" alt="project"> &ndash;&gt;-->
<!--                </div>-->
<!--                <div class="project-description">-->
<!--                  <p>-->
<!--                    This project I built to learn more about writing formulas in JavaScript.-->
<!--                  </p>-->
<!--                </div>-->
<!--                <div class="links">-->
<!--                  <a href="https://github.com/bohdanKulcyckyj/Math_App" target="_blank" rel="noopener">-->
<!--&lt;!&ndash;                    <img src="./assets/github.svg" alt="github" />&ndash;&gt;-->
<!--                  </a>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="flip-card reveal">-->
<!--            <div class="flip-card-inner">-->
<!--              <div class="flip-card-front">-->
<!--                <div class="project-name">-->
<!--                  <h2>Money Manager</h2>-->
<!--                </div>-->
<!--                <div class="project-photo">-->
<!--&lt;!&ndash;                  <img src="./assets/projects/manage-money-tool.png" alt="project-img">&ndash;&gt;-->
<!--                </div>-->
<!--                <div class="used-tech">-->
<!--&lt;!&ndash;                  <img src="./assets/techs/html.svg" alt="html" />&ndash;&gt;-->
<!--&lt;!&ndash;                  <img src="./assets/techs/css.svg" alt="css" />&ndash;&gt;-->
<!--&lt;!&ndash;                  <img src="./assets/techs/javascript.png" alt="js" />&ndash;&gt;-->
<!--&lt;!&ndash;                  <img src="./assets/techs/sass.svg" alt="css" />&ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="flip-card-back">-->
<!--                <div class="file">-->
<!--                  &lt;!&ndash; <img src="./assets/flip-file.svg" alt="project"> &ndash;&gt;-->
<!--                </div>-->
<!--                <div class="project-description">-->
<!--                  <p>-->
<!--                    It was my first bigger project in my very beginning. I learned how to use SASS and ES5/ES6 features to build something useful.-->
<!--                  </p>-->
<!--                </div>-->
<!--                <div class="links">-->
<!--                  <a href="https://github.com/bohdanKulcyckyj/money-storage-app" target="_blank" rel="noopener">-->
<!--&lt;!&ndash;                    <img src="./assets/github.svg" alt="github" />&ndash;&gt;-->
<!--                  </a>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->
<!--    &lt;!&ndash; Projects ends &ndash;&gt;-->

<!--    &lt;!&ndash; Contact starts &ndash;&gt;-->
<!--    <section id="contact">-->
<!--      <h1 class="reveal">Let's get in touch!</h1>-->
<!--&lt;!&ndash;      <img src="./assets/contact/paper-plane.png" alt="plane" class="plane">&ndash;&gt;-->
<!--      <div class="contact-container">-->
<!--        <form action="https://formspree.io/f/mvodowqy" method="POST" autocomplete="off" class="reveal" id="contact-form">-->
<!--          <div class="input-container">-->
<!--            <input type="text" name="name" id="name" placeholder="Name" />-->
<!--          </div>-->

<!--          <div class="input-container">-->
<!--            <input type="text" name="surname" id="surname" placeholder="Surname"/>-->
<!--          </div>-->

<!--          <div class="input-container">-->
<!--            <input type="text" name="phone" id="phone" placeholder="Phone Number"/>-->
<!--          </div>-->

<!--          <div class="input-container">-->
<!--            <input type="email" name="email" id="email" placeholder="E-mail *" required />-->
<!--          </div>-->

<!--          <div class="input-container">-->
<!--            <textarea type="text" name="message" id="text-message" placeholder="Message *" required></textarea>-->
<!--          </div>-->
<!--          <button type="submit" id="form-btn">Send</button>-->
<!--        </form>-->
<!--        <span id="my-form-status">I usually respond due the week</span>-->
<!--      </div>-->
<!--    </section>-->
<!--    &lt;!&ndash; Contact ends &ndash;&gt;-->

<!--&lt;!&ndash;    &lt;!&ndash; Footer starts &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;    <footer id="footer">&ndash;&gt;-->
<!--&lt;!&ndash;      <div class="footer-container">&ndash;&gt;-->
<!--&lt;!&ndash;        <ul class="soc-media-icons">&ndash;&gt;-->
<!--&lt;!&ndash;          <li>&ndash;&gt;-->
<!--&lt;!&ndash;            <a href="https://github.com/bohdanKulcyckyj" target="_blank" rel="noopener">&ndash;&gt;-->
<!--&lt;!&ndash;              <img src="assets/github.svg" alt="github"/>&ndash;&gt;-->
<!--&lt;!&ndash;            </a>&ndash;&gt;-->
<!--&lt;!&ndash;          </li>&ndash;&gt;-->
<!--&lt;!&ndash;          <li>&ndash;&gt;-->
<!--&lt;!&ndash;            <a href="https://www.linkedin.com/in/bohdan-kul%C4%8Dyckyj-b1092819a/" target="_blank" rel="noopener">&ndash;&gt;-->
<!--&lt;!&ndash;              <img src="assets/linkedin.svg" alt="linkedin"/>&ndash;&gt;-->
<!--&lt;!&ndash;            </a>&ndash;&gt;-->
<!--&lt;!&ndash;          </li>&ndash;&gt;-->
<!--&lt;!&ndash;        </ul>&ndash;&gt;-->
<!--&lt;!&ndash;        <p>&copy; Copyright 2021 Bohdan Kulčyckyj. All rights reserved.</p>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--&lt;!&ndash;    </footer>&ndash;&gt;-->
<!--&lt;!&ndash;    &lt;!&ndash; Footer ends &ndash;&gt;&ndash;&gt;-->

<!--&lt;!&ndash;    &lt;!&ndash; Scripts starts &ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;    <script src="https://cdnjs.cloudflare.com/ajax/libs/ScrollMagic/2.0.6/ScrollMagic.min.js" integrity="sha512-kDuUo39RGApdfHkM1WQL8Pg9BYtYGS5rD14AzePsD4SEaixw7v/ykAkLMdsSjuBCNFE6/FAP+WIHh6N6QZJl5w==" crossorigin="anonymous"></script>&ndash;&gt;-->
<!--&lt;!&ndash;    <script src="https://cdnjs.cloudflare.com/ajax/libs/ScrollMagic/2.0.6/plugins/debug.addIndicators.min.js" integrity="sha512-hFyYiBIWCYSyo98oFGWjG5V8HEV4g5O2pxu0mzU8qIMC7lrrwtcKWoRDZcpRL0ywIIM2d/XsBJ9iXNLFf6kIgw==" crossorigin="anonymous"></script>&ndash;&gt;-->
<!--&lt;!&ndash;    <script src="https://cdnjs.cloudflare.com/ajax/libs/ScrollMagic/2.0.6/plugins/animation.gsap.min.js" integrity="sha512-7nSmgdAZ7oPdT5aa2a4YauI8Y1xgaj6zZtQo9+EIEhpcNEiRHPAtyTKYckBQ0lmg1xQKrdWweHBcN/D0tdnvCw==" crossorigin="anonymous"></script>&ndash;&gt;-->
<!--&lt;!&ndash;    <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.7.1/MotionPathPlugin.min.js"></script>&ndash;&gt;-->
<!--&lt;!&ndash;    <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.7.1/ScrollTrigger.min.js"></script>&ndash;&gt;-->
<!--&lt;!&ndash;    <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.7.1/gsap.min.js"></script>&ndash;&gt;-->
<!--&lt;!&ndash;    <script src="planeAnimation.js"></script>&ndash;&gt;-->
<!--&lt;!&ndash;    <script src="formspree.js"></script>&ndash;&gt;-->
<!--&lt;!&ndash;    <script src="index.js"></script>&ndash;&gt;-->
<!--&lt;!&ndash;    &lt;!&ndash; Scripts ends &ndash;&gt;&ndash;&gt;-->
<!--  </div>-->

</template>

<script>
// @ is an alias to /src

// const landing = document.querySelector("#landing");
// function handlePageOnload() {
//   let source = window.getComputedStyle(landing).backgroundImage;
//   console.log(source, "source");
//   let url = source.match(/\((.*?)\)/)[1].replace(/('|")/g,'');
//   console.log(url, "image url");
//
//   let img = new Image();
//   img.onload = function() {
//     if(document.getElementById('loading-screen')) document.getElementById('loading-screen').remove();
//   }
//   img.src = url;
//   if (img.complete) img.onload();
// }
// handlePageOnload();



export default {
  name: 'Home',
}

// import { handleScroll } from 'src/assets/scripts/scroll'

</script>
#loading-screen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #27272b;
    z-index: 20000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

#loading-screen i {
    color: #0ee09a;
    font-size: 3em;
    margin: 0 0 2rem;
}

#loading-screen span {
    font-size: 1.5em;
    color: #0ee09a;
    text-transform: uppercase;
}

#landing {
    padding-top: 5rem;
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #27272b;
    background-image: url("./assets/parallax/paralax-mountains.jpg");
    background-size: cover;
    background-position: bottom;
    background-attachment: fixed;
}

#landing #landing-img {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 80%;
    width: auto;
}

#landing #landing-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #2d313055;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

#landing #landing-text {
    width: 40%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 2.4rem;
    text-align: left;
    color: #f0f0f0;
    font-family: "Kanit", Verdana, sans-serif;
}

#landing #landing-text #landing-h1 {
    font-size: 44px;
    text-align: left;
}

#landing #landing-text #landing-h1 span {
    display: block;
    color: #f0f0f0;
}

#landing #landing-text #landing-h1 #my-name {
    text-transform: uppercase;
    color: #0ee09a;
    font-size: 46px;
}

#landing #landing-text .buttons-container {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-orient: horizontal;
-webkit-box-direction: normal;
-ms-flex-direction: row;
flex-direction: row;
margin: 1.6rem 0 0;
}

#landing #landing-text .buttons-container #cv,
#landing #landing-text .buttons-container #hire-me {
width: 10rem;
margin: 0.5rem;
padding: 0.5rem;
font-size: 1rem;
text-transform: uppercase;
text-decoration: none;
border: 2px solid #f0f0f0;
background-color: transparent;
text-align: center;
cursor: pointer;
color: #f0f0f0;
border-radius: 5px;
-webkit-transition: all 0.3s ease-in;
transition: all 0.3s ease-in;
-webkit-animation: text1 5s 1;
animation: text1 5s 1;
}

#landing #landing-text .buttons-container #hire-me {
color: #0ee09a;
border-color: #0ee09a;
}

#landing #landing-text .buttons-container #hire-me:hover {
color: #f0f0f0;
border-color: #f0f0f0;
}

#landing #landing-text .buttons-container #cv:hover {
color: #0ee09a;
border-color: #0ee09a;
}
<style scoped>

</style>
