<template>
  <div id="app">
    <navbar @scroll="scrollTo" @nightMode="switchMode" :nightMode="nightMode"/>
    <div class="parent">
<!--
Políčka: O mně (Sobre mim)

Ceník a dodací lhůta (Preços e prazos)

Překlady (Traduções)

Kontakt (Contato)

Recenze (Feedback)
-->
      <Home :nightMode="nightMode" />
      <About2 id="about" :nightMode="nightMode" />
      <Pricing id="pricing" :nightMode="nightMode" />
      <Translations id="translations" :nightMode="nightMode" />
      <Contact id="contact" :nightMode="nightMode" />
<!--      <Feedback :nightMode="nightMode" />-->
      <Footer :nightMode="nightMode" />
    </div>
<!--    <div class="wrapper flex-grow-1">-->
<!--      <div class="d-md-none">-->
<!--        <router-view/>-->
<!--      </div>-->
<!--      <div class="views d-none d-md-block">-->
<!--        <router-view/>-->
<!--      </div>-->
<!--      <div class="fluid-container footer">-->
<!--        <p hidden class="text-center">this gonna be footer at the bootom.</p>-->
<!--      </div>-->
<!--      <footer />-->
<!--    </div>-->





  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import AboutMe from "@/components/AboutMe";
import About2 from "@/components/About2";
import Footer from "@/components/Footer";
import ContactMe from "@/components/ContactMe";
import Contact from "@/components/Contact";
import Translations from "@/views/Translations";
import Feedback from "@/views/Feedback";
import Pricing from "@/views/Pricing";
export default {
  name: "app",
  components: {
    Feedback,
    Translations,
    Contact,
    ContactMe,
    AboutMe,
    About2,
    Pricing,
    Navbar,
    Footer
  },
  data() {
    return {
      nightMode: false,
    };
  },
  mounted() {
    ["about", "pricing", "translations", "contacts", "feedback"].forEach((l) => {
      if (window.location.href.includes(l)) {
        var elementPosition = document.getElementById(l).offsetTop;
        window.scrollTo({ top: elementPosition - 35, behavior: "smooth" });
      }
    });
  },
  methods: {
    switchMode(mode) {
      if (this.config.use_cookies) {
        this.$cookie.set("nightMode", mode);
      }
      this.nightMode = mode;
    },
    scrollTo(ele) {
      if (ele == "home") {
        this.$router.push(`/`);
        window.scrollTo({ top: -80, behavior: "smooth" });
      } else {
        var elementPosition = document.getElementById(ele).offsetTop;
        window.scrollTo({ top: elementPosition - 35, behavior: "smooth" });
        if (this.$router.history.current.path !== `/${ele}`)
          this.$router.push(`/${ele}`);
      }
    },
    //Smooth Scrolling
    navigateSmooth(id) {
      let element = document.querySelector(id);
      if(id === "#landing") {
        window.scrollTo({top: element.offsetTop, left: 0, behavior: 'smooth'});
      } else {
        window.scrollTo({top: element.offsetTop  - 80, left: 0, behavior: 'smooth'});
      }
    }
  },
}
</script>

<style lang="scss">
#app {
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 18px;
  
  //color: #2c3e50;
  //background-color: rgb(33, 34, 36);
}
.views {
  margin-left: 200px;
}

.fluid-container.footer{
  display: flex;
  flex-direction: column;
}



</style>
