<template>
  <div
    class="py-4 p-st"
    :class="{
      'bg-light': !nightMode,
      'bg-dark2': nightMode,
      'text-light': nightMode,
    }"
  >
    <div class="container">
      <div
          class="text-center"
        data-aos="fade"
        data-aos-once="true"
        data-aos-duration="1000"
      >
        <span
          class="title text-center section-heading"
          :class="{ pgray: !nightMode, 'text-light': nightMode }"
          >{{ $t('aboutme')}}</span
        >
      </div>
<!--      <hr-->
<!--        width="50%"-->
      <hr
          width="100%"
        :class="{ pgray: !nightMode, 'bg-secondary': nightMode }"
      />
      <div class="row">
<!--        <div class="col-xl-6 col-bg-6 col-md-6 col-sm-12">-->
        <div class="col-xl-12 col-bg-12 col-md-12 col-sm-12">
<!--          <Timeline :data="education" :nightMode="nightMode" />-->
          <TimelineEducation :data="education" :nightMode="nightMode" />
        </div>
<!--        <div class="col-xl-6 col-bg-6 col-md-6 col-sm-12">-->
<!--          <Timeline :data="experience" :nightMode="nightMode" />-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>


<script>
import Timeline from "./helpers/Timeline";
import TimelineEducation from "@/components/TimelineEducation";

export default {
  name: "About2",
  components: {
    TimelineEducation,
    Timeline,
  },
  props: {
    nightMode: {
      type: Boolean,
    },
  },
  data() {
    return {
      education: {
        title: "education",
        data: [1, 2],
      }
    };
  },
};
</script>

<style scoped>
.title {
  font-size: 30px;
  font-weight: 500;
}
</style>
