var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-4 p-st",class:{
    'bg-light': !_vm.nightMode,
    'bg-dark2': _vm.nightMode,
    'text-light': _vm.nightMode,
  },attrs:{"id":"contact"}},[_c('div',{staticClass:"contact-container"},[_c('div',{staticClass:"text-center",attrs:{"data-aos":"fade","data-aos-once":"true","data-aos-duration":"1000"}},[_c('div',{staticClass:"title text-center contact-title",class:{ pgray: !_vm.nightMode, 'text-light': _vm.nightMode }},[_vm._v(_vm._s(_vm.$t('contact')))]),_c('span',{staticClass:"title text-center contact-title",class:{ pgray: !_vm.nightMode, 'text-light': _vm.nightMode }},[_vm._v(_vm._s(_vm.$t('reachMe')))])]),_c('hr',{class:{ pgray: !_vm.nightMode, 'bg-secondary': _vm.nightMode },attrs:{"width":"100%"}}),_c('br'),(_vm.show)?_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.sendEmail(_vm.form)},"reset":_vm.onReset}},[_c('b-form-group',{staticClass:"mb-3 contact-form",attrs:{"id":"input-group-2","label":_vm.$t('name'),"label-for":"input-2"}},[_c('b-form-input',{staticClass:"contact-input input-container",staticStyle:{"transition-delay":"0.2s"},attrs:{"cols":"255","id":"input-2","placeholder":_vm.$t('name'),"required":"","type":"text","name":"user_name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('b-form-group',{staticClass:"my-3",attrs:{"id":"input-group-1","label":_vm.$t('email'),"label-for":"input-1","data-aos":"fade-up","data-aos-once":"true","data-aos-duration":"1000"}},[_c('b-form-input',{staticClass:"contact-input input-container",class:{
            pgray: !_vm.nightMode,
            'pgray-dark': _vm.nightMode,
            'text-light': _vm.nightMode,
          },staticStyle:{"transition-delay":"0.4s"},attrs:{"id":"input-1","type":"email","placeholder":_vm.$t('email'),"required":"","name":"user_email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('b-form-group',{staticClass:"my-3",attrs:{"id":"input-group-3","label":_vm.$t('text'),"label-for":"input-3","data-aos":"fade-up","data-aos-once":"true","data-aos-duration":"1000"}},[_c('b-form-textarea',{staticClass:"contact-input input-container input-container-textarea",class:{
            pgray: !_vm.nightMode,
            'pgray-dark': _vm.nightMode,
            'text-light': _vm.nightMode,
          },staticStyle:{"transition-delay":"0.6s"},attrs:{"id":"textarea","name":"comment","placeholder":_vm.$t('text'),"max-rows":"6","rows":"4"},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}})],1),_c('b-button',{staticClass:"mt-1 btn mb-3 contact-submit",attrs:{"type":"submit","variant":"primary","data-aos":"fade","data-aos-once":"true","data-aos-duration":"1000","data-aos-offset":"50"}},[_vm._v(" Send ")])],1):_vm._e(),_c('Snackbar',{attrs:{"showSnackbar":_vm.showSnackbar,"snackbarMessage":_vm.snackbarMessage,"snackbarColor":_vm.snackbarColor},on:{"close":_vm.closeSnackbar}}),_c('ul',{staticClass:"soc-media-icons"},[_c('li',[_c('a',{attrs:{"href":"mailto:carolineklauszova@gmail.com","target":"_blank","rel":"noopener"}},[_c('b-img',{attrs:{"src":require('../assets/gmail_icon_2020.svg'),"alt":"gmail"}})],1)]),_c('li',[_c('a',{attrs:{"href":"https://api.whatsapp.com/send?phone=+420731964961","target":"_blank","rel":"noopener"}},[_c('b-img',{attrs:{"src":require('../assets/WhatsApp.svg'),"alt":"whatsapp"}})],1)])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }